import swal from "sweetalert";
export const alertaErro = (texto) => {
    swal({
        title: "Atenção",
        text: texto,
        icon: "error",
        dangerMode: true,
        className: 'alertas-cad-empresa'
    })
}
export const alertaSucesso = (texto) => {
    swal({
        title: "Sucesso",
        text: texto,
        icon: "success",
        dangerMode: true,
        className: 'alertas-cad-empresa'
    })
}